import React, { useEffect, useState } from 'react';
import deftemples from '../../assets/images/templeicon.jpg'
import { Link } from 'react-router-dom';
import { getImageAPI } from '../../http-common';
import { message } from 'antd';
import spin from '../../assets/images/Rolling.gif'
import TempleMap from '../pages/Homepage/Temples/templeMap';
import { Navigate, useLocation, useNavigate, useParams, useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { deleteTempleImage, fetchTempleDetails, postUploadImageRequest } from '../../redux/actions/acions';
import temple1 from '../../assets/images/templeimg.png'
import temple2 from '../../assets/images/templeimg2.png'
import { useTranslation } from 'react-i18next';
import Hammer from 'react-hammerjs';
import article1 from "../../assets/images/altcles1.jpg";
import article2 from "../../assets/images/altcles-copy.jpg";
import article3 from "../../assets/images/altcles-3y.jpg";
import CustomToastContainer from './CustomToastContainer';

const TempleCard = ({ id, newImage, index, title, city, state, addr1, addr2, audio, text, tList, src, temple, favList, toggleFavorite, handleClick, toggleFilled, storeTempleDetails, latit, longi, link, handleDelete, target }) => {
    const [showImage, setShowImage] = useState(true);
    const { t } = useTranslation();
    const [messageApi, contextHolder] = message.useMessage();


    const failure = (msg) => {
        messageApi.open({
            type: 'error',
            content: 'Please Login to see additional features',
        });
    };

    const success = (tname) => {
        messageApi.open({
            type: 'success',
            content: `Added ${tname} to favorites`,
        });
    };
    const warning = (tname) => {
        messageApi.open({
            type: 'warning',
            content: `Removed ${tname} from favourites`,
        });
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowImage(false);
        }, 2000); // Hide the image after 2 seconds

        return () => {
            clearTimeout(timer);
        };
    }, []);

    const [modalVisible, setModalVisible] = useState(false);

    // Function to open the modal
    const openModal = () => {
        setModalVisible(true);
    };


    // Function to close the modal
    const closeModal = () => {
        setModalVisible(false);
    };

    const isLocationButtonVisible = () => {
        // Replace the following logic with your own to determine the page type
        const isBookPage = window.location.pathname.includes('/books');
        const isLiveTVPage = window.location.pathname.includes('/templeDetails');
        const isArticlesPage = window.location.pathname.includes('/articles');
        const isMantrasPage = window.location.pathname.includes('/mantras');
        const isPriestBySpclty = window.location.pathname.includes('/priestBySpclty');
        const isAudiosPage = window.location.pathname.includes('/audios');
        const isResFavPage = window.location.pathname.includes('/resourceFavorite');
        // Return true if the location button should be visible, false otherwise
        return !(isBookPage || isLiveTVPage || isArticlesPage || isMantrasPage || isPriestBySpclty || isAudiosPage || isResFavPage);
    };

    const isPreviewButtonVisible = () => {
        // Replace the following logic with your own to determine the page type
        const isBookPage = window.location.pathname.includes('/books');
        const isLiveTVPage = window.location.pathname.includes('/templeDetails');
        const isArticlesPage = window.location.pathname.includes('/articles');
        const isMantrasPage = window.location.pathname.includes('/mantras');
        const isPriestBySpclty = window.location.pathname.includes('/priestBySpclty');
        const isAudiosPage = window.location.pathname.includes('/audios');
        const isDetailsPage = window.location.pathname.includes('/templeDetails');
        const isTempleResPage = window.location.pathname.includes('/templeResource');
        const isResFavPage = window.location.pathname.includes('/resourceFavorite');
        // Return true if the location button should be visible, false otherwise
        return !(isBookPage || isLiveTVPage || isArticlesPage || isMantrasPage || isPriestBySpclty || isAudiosPage || isDetailsPage || isTempleResPage || isResFavPage);
    };
    const userid = localStorage.getItem('urole')

    const isEditButtonVisible = () => {
        // Replace the following logic with your own to determine the page type
        const isBookPage = window.location.pathname.includes('/books');
        const isLiveTVPage = window.location.pathname.includes('/articles');
        const isLiveDarshanPage = window.location.pathname.includes('/templeDetails');
        const isMantrasPage = window.location.pathname.includes('/mantras');
        const isAudiosPage = window.location.pathname.includes('/audios');
        // Return true if the edit button should be visible, false otherwise
        return userid === 'AS' || userid === 'AD' ? (isBookPage || isLiveTVPage || isLiveDarshanPage || isMantrasPage || isAudiosPage) : false;
    };

    useEffect(() => {
        const handlePopState = (event) => {
            if (modalVisible) {
                closeModal();
                event.preventDefault();
            }
        };
        const handleKeyDown = (event) => {
            if (modalVisible && event.key === 'Escape') {
                closeModal();
                event.preventDefault();
            }
        };
        if (modalVisible) {
            // Add history entry and listeners
            window.history.pushState({ popupOpen: true }, '');
            window.addEventListener('popstate', handlePopState);
            window.addEventListener('keydown', handleKeyDown);
        }
        return () => {
            // Cleanup listeners when popup closes or component unmounts
            window.removeEventListener('popstate', handlePopState);
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [modalVisible, closeModal]);

    const isAudiosPage = () => {
        const isAudioPage = window.location.pathname.includes('/audios');
        return isAudioPage ? isAudioPage : false;
    };

    const isTemple = () => {
        const isTemplesPage = window.location.pathname.includes('/templesList')
            || window.location.pathname.includes('/searchResults')
            || window.location.pathname.includes('/templeGroup')
            || window.location.pathname.includes('/templeDetails')
            || window.location.pathname.includes('/recentlyViewed')
            || window.location.pathname.includes('/contribution')
            || window.location.pathname.includes('/nearby')
            || window.location.pathname.includes('/searchFavourite')
            || window.location.pathname.includes('/pendingTemples')
            || window.location.pathname.includes('/OutsideIndia')
            || window.location.pathname.includes('/state');
        return isTemplesPage ? isTemplesPage : false;
    };

    const isDeleteButtonVisible = () => {
        // Replace the following logic with your own to determine the page type
        const isContributionPage = window.location.pathname.includes('/contribution');


        // Return true if the edit button should be visible, false otherwise
        return userid === 'AD' ? (isContributionPage) : false;
    };

    const articleImages = [
        { src: article1, alt: 'article1' },
        { src: article2, alt: 'article2' },
        { src: article3, alt: 'article3' },
    ];

    const getPriestImage = (resType, index = 0) => {


        // Cycle through images based on index
        // if (resType === 'articles') {
        //   return articleImages[index % articleImages.length]; // Cycle through the images
        // }

        // Handle other resTypes as needed
        switch (resType) {
            case 'mantras':
                return 'https://tse2.mm.bing.net/th?id=OIP.-rKodeHo2kIj6qwrnp2syAHaHD&pid=Api&P=0&h=180';
            case 'books':
                return 'https://scholarlykitchen.sspnet.org/wp-content/uploads/2018/07/iStock-506432952.jpg';
            case 'audios':
                return 'https://tse1.mm.bing.net/th?id=OIP.OLnrsvISPCD-Y65PfWSmtQHaE8&pid=Api&P=0&h=180';
            case 'livetv':
                return 'https://thesonofgodorg.files.wordpress.com/2018/01/01.png';
            case 'videos':
                return 'https://tse4.mm.bing.net/th?id=OIP.nHqa9osiCo-8DK0cbD4VPAHaEK&pid=Api&P=0&h=180';
            default:
                return deftemples; // Default image
        }
    };








    const isPriestPage = window.location.pathname.includes('/books') ||
        window.location.pathname.includes('/articles') ||
        window.location.pathname.includes('/mantras') ||
        window.location.pathname.includes('/audios') ||
        window.location.pathname.includes('/priestBySpclty') 
        // window.location.pathname.includes('/templeDetails');


    const dispatch = useDispatch();
    const [newImagePath, setNewImagePath] = useState('');
    const handleGalleryClick = () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*';

        fileInput.addEventListener('change', (event) => {
            const selectedFile = event.target.files[0];
            if (selectedFile) {
                const formData = new FormData();
                formData.append('file', selectedFile);
                dispatch(postUploadImageRequest(id, formData, success, failure));
                // Simulating an asynchronous upload process
                setTimeout(() => {
                    // Get the URL or path of the uploaded image and update the state
                    const uploadedImagePath = URL.createObjectURL(selectedFile);
                    setNewImagePath(uploadedImagePath);
                }, 1000); // Simulating a delay for upload

                event.target.value = null; // Clear the input to allow selecting the same file again
            }
        });

        document.body.appendChild(fileInput);
        fileInput.click();
    };

    const onFinish = (imgid) => {
        const data = {
            'active': false
        };
        dispatch(deleteTempleImage(temple.tid, imgid, data));
    };

    // const handleClick = () => {
    //     // Check if the link corresponds to the books or articles page
    //     if (target === '_blank') {
    //         // If it does, do nothing else
    //         return;
    //     }
    //     // For other pages, execute storeTempleDetails
    //     storeTempleDetails(temple);
    // };

    const [modalPreviewVisible, setModalPreviewVisible] = useState(false);
    const [currentTempleId, setCurrentTempleId] = useState(null);

    const openPreviewModal = (id) => {
        setCurrentTempleId(id);
        setModalPreviewVisible(true);
    };

    const closePreviewModal = () => {
        setModalPreviewVisible(false);
    };

    useEffect(() => {
        // Check if the current temple is in the favorite list
        if (favList && currentTemple) {
            setIsFavourite(favList.includes(currentTemple.tid));
        }
    }, [favList, currentTemple]);

    const cachedFavourites = JSON.parse(localStorage.getItem('favourites')) || [];
    const cachedLibraryFavourites = JSON.parse(localStorage.getItem('articles')) || [];

    const handleNext = () => {
        const currentIndex = tList.findIndex(temple => temple.tid === currentTempleId);
        if (currentIndex < tList.length - 1) {
            const nextTempleId = tList[currentIndex + 1].tid;
            setCurrentTempleId(nextTempleId);

            // Update favorite status dynamically
            const isFavourite = cachedFavourites.includes(nextTempleId);
            console.log(`Next Temple ID: ${nextTempleId}, Is Favourite: ${isFavourite}`);
        }
    };

    const handlePrevious = () => {
        const currentIndex = tList.findIndex(temple => temple.tid === currentTempleId);
        if (currentIndex > 0) {
            const previousTempleId = tList[currentIndex - 1].tid;
            setCurrentTempleId(previousTempleId);

            // Update favorite status dynamically
            const isFavourite = cachedFavourites.includes(previousTempleId);
            console.log(`Previous Temple ID: ${previousTempleId}, Is Favourite: ${isFavourite}`);
        }
    };

    const [isFavourite, setIsFavourite] = useState(false);

    useEffect(() => {
        if(isTemple){
            const cachedFavourites = JSON.parse(localStorage.getItem('favourites')) || [];
            setIsFavourite(cachedFavourites.includes(currentTempleId));
        }
        else{
            const cachedFavourites = JSON.parse(localStorage.getItem('articles')) || [];
            setIsFavourite(cachedFavourites);
        }
       
    }, [currentTempleId]);

    const currentTemple = tList && tList.find(temple => temple.tid === currentTempleId);
    const capitalizeFirstLetter = (string) => {
        if (!string) return '';
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const { tid } = useParams();
    const [currentTempleIndex, setCurrentTempleIndex] = useState(0);
    const storedTemplesList = JSON.parse(localStorage.getItem('templesList')) || [];
    const storedTid = localStorage.getItem('currentTempleTid') || tid;
    const storedLanguage = localStorage.getItem('lng') || 5;
    const [loading, setLoading] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage);

    const handleShowDetails = () => {
        const currentTid = localStorage.getItem('currentTempleTid') || storedTid;
        const currentTemple = storedTemplesList[currentTempleIndex];

        if (currentTemple) {
            window.history.replaceState(null, '', `/templeDetails/${currentTid}`);
            dispatch(fetchTempleDetails(storedLanguage, currentTid));
            setModalPreviewVisible(false);
            window.location.reload();
        }
    };

    useEffect(() => {
        const handlePopState = (event) => {
            if (modalPreviewVisible) {
                closePreviewModal();
                event.preventDefault();
            }
        };
        const handleKeyDown = (event) => {
            if (modalPreviewVisible && event.key === 'Escape') {
                closePreviewModal();
                event.preventDefault();
            }
        };
        if (modalPreviewVisible) {
            // Add history entry and listeners
            window.history.pushState({ popupOpen: true }, '');
            window.addEventListener('popstate', handlePopState);
            window.addEventListener('keydown', handleKeyDown);
        }
        return () => {
            // Cleanup listeners when popup closes or component unmounts
            window.removeEventListener('popstate', handlePopState);
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [modalPreviewVisible, closePreviewModal]);

    const isMobile = window.innerWidth <= 768;
    return (
        <>
            <div class="popup-bg" style={{ display: modalVisible ? 'flex' : 'none' }}>
                <div class="popup-main animate__animated animate__fadeInUp ">
                    <div class="popup-main-header">
                        <span class="popup-main-header-text"> {title}</span>
                        <div class="popup-close" onClick={closeModal}><i class="fa-solid fa-xmark"></i></div>
                        <div class="popup-back" onClick={closeModal}><i class="fa-solid fa-chevron-left"></i></div>
                    </div>
                    <div class="popup-main-container">
                        <div class="d-block htinner-popup-main-container">
                            <TempleMap
                                tname={title}
                                latit={latit}
                                longi={longi}
                                zoomOutFactor={10}
                                city={temple ? temple.city : null}
                                state={temple ? temple.st : null}
                                mapContainerStyle={{height: isMobile ? '72vh' : '64vh',}}
                            />
                        </div>
                    </div>
                    <div class="popup-main-footer">

                        <a type="primary" className="button_move" style={{ float: 'right' }} onClick={() => { closeModal() }}>
                            {t('label_cancel')}
                        </a>
                    </div>
                </div>
            </div>
            {/* <TemplePreviewModal
                tList={tList && tList}
                isVisible={isPreviewModalVisible}
                onClose={closePreviewModal}
            /> */}
            {/* {isPreviewButtonVisible() && ( */}
                <div className="popup-bg" style={{ display: modalPreviewVisible ? 'flex' : 'none' }}>
                    <CustomToastContainer />
                    <div className="popup-main animate__animated animate__fadeInUp">
                        <div class="popup-main-header">
                            <span className="popup-main-header-text">{currentTemple && currentTemple.tname}</span>
                            <div className="popup-close" onClick={closePreviewModal}>
                                <i className="fa-solid fa-xmark"></i>
                            </div>
                            <div className="popup-back" onClick={closePreviewModal}>
                                <i className="fa-solid fa-chevron-left"></i>
                            </div>
                        </div>
                        <div className="popup-main-container">

                            <div className="d-block htinner-popup-main-container">

                                {currentTemple && (
                                    <>
                                        <Link className='ignore-styles' to={`/templeDetails/${currentTemple.tid}?prevPage=${window.location.pathname}`}>
                                            <img
                                                className="grp-descr-img"
                                                alt={currentTemple.tname}
                                                src={currentTemple.imgpath ? getImageAPI() + currentTemple.imgpath : deftemples}
                                            />
                                        </Link>
                                        <Hammer
                                            onSwipe={(event) => {
                                                if (event.direction === 2) { // Left swipe for next
                                                    handleNext();
                                                } else if (event.direction === 4) { // Right swipe for previous
                                                    handlePrevious();
                                                }
                                            }}
                                        >
                                            <div className='diag-image-content' style={{ whiteSpace: "pre-wrap" }}>
                                                <p><strong>{t('label_temple')} :</strong> {currentTemple.tname}</p>
                                                <p><strong>{t('label_addr')} : </strong> {currentTemple.addr1 || 'N/A'}{', ' || currentTemple.addr2 || ''}</p>
                                                <p><strong>{t('label_state_city')} :</strong> {capitalizeFirstLetter(currentTemple.city)}, {capitalizeFirstLetter(currentTemple.st)}</p>
                                                <p><strong>{t('label_deity_name')} :</strong> {currentTemple.deityname || 'N/A'}</p>
                                            </div>
                                        </Hammer>
                                    </>
                                )}
                            </div>

                        </div>

                        <div className="popup-main-footer">
                            <div className="tile-quicklink" style={{ marginRight: "60%", bottom: "20px" }}>
                                <a
                                    className="share-button"
                                    onClick={() => {
                                        if (navigator.share) {
                                            navigator.share({
                                                title: "Know " + title,
                                                text: "Know " + title,
                                                url: `https://www.templeswiki.com/templeDetails/${id}?prevPage=${window.location.pathname}`
                                            })
                                                .then(() => console.warn("Share successful."))
                                                .catch((error) => console.error("Error sharing:", error));
                                        } else {
                                            // Fallback for browsers that do not support the Web Share API
                                            console.error("Web Share API not supported.");
                                        }
                                    }}
                                >
                                    <i title="Share" className="fa-solid fa-share-nodes" style={{ fontSize: '24px' }}></i>
                                </a>

                                {favList && (
                                    <a
                                        className="share-button"
                                        onClick={() => toggleFavorite(currentTemple, title)}
                                    >
                                        {isFavourite ? (
                                            <i className="fa-solid fa-heart" style={{ fontSize: '24px' }}></i>
                                        ) : (
                                            <i className="fa-regular fa-heart" style={{ fontSize: '24px' }}></i>
                                        )}
                                    </a>
                                )}
                            </div>
                            <a
                                onClick={handleShowDetails}
                                className="button_move"
                                style={{ float: 'right', marginRight: '10px' }}
                                disabled={loading}
                            >
                                {t('label_show_details')}
                            </a>
                            <button
                                onClick={handlePrevious}
                                className="button_move"
                                style={{ float: 'right', marginRight: "10px" }}
                                disabled={tList&&tList.findIndex(temple => temple.tid === currentTempleId) === 0}
                            >
                                {t('label_previous')}
                            </button>
                            <button
                                onClick={handleNext}
                                className="button_move"
                                style={{ float: 'right' }}
                                disabled={tList&&tList.findIndex(temple => temple.tid === currentTempleId) === tList.length - 1}
                            >
                                {t('label_next')}
                            </button>
                        </div>

                    </div>
                </div >
            {/* )} */}
            <div class="tiles-design animate__animated animate__flipInX" key={id}>
                <div >
                    {/* <Link to={link} target={target} onClick={handleClick}> */}
                    <Link
                        to={link}
                        target={target}
                        onClick={(e) => {
                            // if (text === 'articles' && temple.src_url === 'www.templeswiki.com') {
                            //     e.preventDefault(); // Prevent navigation for articles or templeswiki.com
                            //     handleClick(); // Call the handleClick to show the popup
                            // }
                            // else if (text === 'mantras' && temple.src_url === 'www.templeswiki.com') {
                            //     e.preventDefault(); // Prevent navigation for articles or templeswiki.com
                            //     handleClick(); // Call the handleClick to show the popup
                            // }
                            // else if (window.location.pathname.includes('/templeDetails')) {
                            //     // Scroll to top and manually push state for temple details page only
                            //     // Prevent default link behavior
                            //     if (handleClick) handleClick(); // Scroll to top
                            //     window.history.pushState({}, '', link); // Update URL for same-origin links
                            // }
                        //    if (isTemple()) {
                        //         if (typeof storeTempleDetails === 'function') {
                                    storeTempleDetails(temple); // Call storeTempleDetails if it exists
                            //     }
                            // }
                        }}
                    >
                        {(temple && temple.imgpath != null) ? (

                            <div className="card-img-container">
                                {/* {!isDetailsPage() && (
                                <div className="close-icon-container">
                                    {(userid === 'TA' || userid === 'TU') && (
                                        <CloseCircleOutlined
                                            className="close-icon1"
                                            onClick={() => onFinish(temple.imgid)}
                                        />
                                    )}
                                </div>
                            )} */}

                                <div className="card-img" style={{
                                    backgroundImage: showImage ? `url(${spin})` :
                                        `url(${temple.imgpath === "base_images_01/durga_02.jpg" ? temple2 :
                                            temple.imgpath === "base_images_01/durga_01.jpg" ? temple1 :
                                                getImageAPI() + temple.imgpath})`
                                }}></div>

                                {/* <div className="card-img" style={{ backgroundImage: showImage ? `url(${spin})` : `url(${getImageAPI()}${temple.imgpath})` }}></div> */}
                            </div>
                        // ) : isPriestPage && temple.res_type === 'articles' ? (
                        //     <div className="card-img-container">
                        //         <div className="card-img">
                        //             <img
                        //                 className="card-img"
                        //                 alt={articleImages[id % articleImages.length].alt}
                        //                 src={articleImages[id % articleImages.length].src}
                        //             />
                        //         </div>
                        //     </div>
                        ) : (
                            // isPriestPage ? (
                            //     <img className="card-img" alt="temples" src={getPriestImage(temple.res_type)} />
                            // ) : (
                                // <img className="card-img" alt="temples" src={deftemples} />
                                <div class="card-img-container">
                                    <div className="card-img"
                                        style={{ backgroundImage: `url(${temple2})` }}

                                    ></div>
                                </div>
                            // )
                        )}

                        <div className="tile-text">
                            <div>

                                <h6 className="home-temple-text" title={title}>{title}</h6>
                                {/* {isTemple() ? (
                                    <> */}
                                        <p className="mb-1 f-grey tile-add">{addr1}, {addr2}</p>
                                        <p className="mb-1 f-grey tile-add">{city}, {state}</p>
                                    {/* </>
                                ) : (
                                    <p className="mb-1 f-grey tile-add">{city}</p>
                                )} */}


                            </div>
                            {/* {isAudiosPage() && (
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',

                                }}>
                                    <audio id={'song00' + id} controls="controls" onLoadedMetadata={event => console.log("MP3=" + event.target.duration)}>
                                        <source src={link} type="audio/mpeg" />
                                    </audio>
                                </div>
                            )} */}

                        </div>
                    </Link>

                    <div className="tile-quicklink">
                        {/* {isPreviewButtonVisible() && ( */}
                            <a className="icon-button" onClick={() => openPreviewModal(id)}>
                                <i className="fa-solid fa-eye"></i>
                            </a>
                        {/* )} */}

                        {/* {isLocationButtonVisible() && ( */}
                            <a className="icon-button" onClick={openModal}>
                                <i className="fa-solid fa-location-dot"></i>
                            </a>
                        {/* )} */}





                        {/* {isEditButtonVisible() && (
                            <a href={`/editResource/${temple.rid}`} className="icon-button">
                                <i className="fa-solid fa-edit"></i>
                            </a>
                        )} */}

                        {isDeleteButtonVisible() && (
                            <a className="icon-button" onClick={handleDelete}>
                                <i className="fa-solid fa-trash"></i>
                            </a>
                        )}

                        <a
                            className="share-button"
                            onClick={() => {
                                if (navigator.share) {
                                    navigator.share({
                                        title: "Know " + title,
                                        text: "Know " + title,
                                        url: `https://www.templeswiki.com/templeDetails/${id}?prevPage=${window.location.pathname}`
                                    })
                                        .then(() => console.warn("Share successful."))
                                        .catch((error) => console.error("Error sharing:", error));
                                } else {
                                    // Fallback for browsers that do not support the Web Share API
                                    console.error("Web Share API not supported.");
                                }
                            }}
                        >
                            <i title="Share" className="fa-solid fa-share-nodes"></i>
                        </a>

                        {favList && (
                            <a
                                className="share-button"
                                onClick={() => toggleFavorite(temple, title)}
                            >
                                {favList.some(t => t === id) ? (
                                    <i className="fa-solid fa-heart"></i>
                                ) : (
                                    <i className="fa-regular fa-heart"></i>
                                )}
                            </a>
                        )}
                    </div>

                </div>
            </div >

        </>


    )
}

export default TempleCard;