import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Typography from 'antd/es/typography/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, Result, Row, Skeleton, message } from 'antd';
import { getDailyHoroscopeLis, getDailyHoroscopeList, getDailyHoroscopeListLoading, getDailyHoroscopeLoading } from '../../../../redux/selectors/selectors';
import { fetchDailyHoroscope, fetchDailyHoroscopeList } from '../../../../redux/actions/acions';
import deftemples from '../../../../assets/images/templeicon.png';
import { useTranslation } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';
import Footer from '../footer/footer';
import nodata from '../../../../assets/images/nodata5.jpg';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';

const Horoscope = () => {
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('lng') || 5;

    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();

    const failure = (msg) => {
        showToast('error', msg)
        // messageApi.error(msg); // You can use 'error' directly
    };

    const { hDetails, hLoading } = useSelector((state) => ({
        hDetails: getDailyHoroscopeLis(state),
        hLoading: getDailyHoroscopeListLoading(state),
    }));

    const userid = localStorage.getItem('urole')

    useEffect(() => {
        dispatch(fetchDailyHoroscopeList(storedLanguage, 0));
    }, [dispatch]);

    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <div className='mainsec'>
            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t('label_daily_panchamgam')}</span>
                </div>
                <div></div>
            </div>
            {contextHolder}

            <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link' >
                <Link style={{ color: 'black' }} to='/'>
                    <span className="bc-active-link">{t('Home')}</span>
                </Link> -
                {/* <Link style={{ color: 'black' }} to='/templeCtgry'><span className="bc-link"> Temples</span></Link> - */}
                <span className="bc-active-link" onClick={handleGoBack}> {t('label_panchangam')}</span> - <span className="bc-link">{t("label_daily_panchamgam")}</span>
            </div>
            
            <div class="bg-details-highlight" >
            <div class=" details-headertxt-singleLine container">
                    <div class="header_txt"> {t("label_daily_panchamgam")}</div>
                    <div class="details-headertxt-singleLine-btns">
                        {userid === "AD" || userid === "AS" ? (
                            <>
                                <Link to='/editDailyHoroscope/0'>
                                    <button type="button" className="button_move">{t("label_create_daily_horo")}</button>
                                </Link>
                                <Link to='/adddailypanchangam'>
                                    <button type="button" className="button_move" style={{ marginLeft: "5px" }}>{t("label_create_daily_panchangam")}</button>
                                </Link>
                            </>
                        ) : null}
                        <Link to='/weeklyHoroscope'><button type="button" class="button_move" style={{ marginLeft: "5px" }} >{t("label_weekly_horoscope")}</button></Link>
                    </div>
                </div>
            </div>

            <div class="mainsec-block-addtemple-detailspage">
                <div className="container">
                    {hLoading ? (
                        <Skeleton active />
                    ) : hDetails && hDetails.length > 0 ? (
                        hDetails.map((notificationList) => (
                            <Link to={`/horoscopeDetails/${notificationList.hid}`} className="notification-link" key={notificationList.hid}>
                                <div className="tiles-designs-events" style={{padding:"10px 5px"}}>
                                    <div className="d-flex flex-space-between">
                                        <div className="f1 events-block-link-title">
                                            <i className="fa-solid fa-calendar-week mr-3"></i>
                                            <span className="f1">{notificationList.title}</span>
                                        </div>
                                        <Button
                                            className="share-button"
                                            style={{ color: '#c93937', marginTop: "10px" }}
                                            type="link"
                                            onClick={() => {
                                                if (navigator.share) {
                                                    navigator
                                                        .share({
                                                            title: notificationList.title,
                                                            text: "Check out this temple!",
                                                            url: `https://www.templeswiki.com/horoscopeDetails/${notificationList.hid}`,
                                                        })
                                                        .then(() => console.warn("Share successful."))
                                                        .catch((error) => console.error("Error sharing:", error));
                                                } else {
                                                    console.error("Web Share API not supported.");
                                                }
                                            }}
                                        >
                                            <i title="Share" className="fa-solid fa-share-nodes"></i>
                                        </Button>
                                    </div>
                                    <span className="f1 events-block-link-value">{notificationList.author}</span>
                                </div>
                            </Link>
                        ))
                    ) : (
                        <Result
                            status="404"
                            title={t("label_nodata_found")}
                            subTitle={t("label_no_horo_msg")}
                        />
                    )}
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default Horoscope;
