import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, message, Row, Col, Image, Result, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addFavourites, fetchBooksFavourites, fetchContributions, fetchFavourites, fetchGodNames, fetchResArticles, fetchResAudios, fetchResLivetv, fetchResMantras, fetchResVideos, fetchResourceFavourites, fetchTemplesList, postBooksFavourites, postFavourites, removeBooksFavourites, removeFavourites, retrieveGodNames } from '../../../../redux/actions/acions';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { HeartOutlined, HeartFilled } from '@ant-design/icons';
import { getAudioAPI, getImageAPI } from "../../../../http-common";
import deftemples from '../../../../assets/images/templeicon.jpg';
import { getArticlesError, getArticlesList, getArticlesLoading, getAudios, getAudiosError, getAudiosLoading, getBooksFavouritesError, getBooksFavouritesList, getBooksFavouritesLoading, getFavouritesError, getFavouritesList, getFavouritesLoading, getGodNames, getGodNamesError, getGodNamesLoading, getMantras, getMantrasError, getMantrasLoading, getResArticles, getResArticlesError, getResArticlesLoading, getResAudiosError, getResAudiosList, getResAudiosLoading, getResLivetvError, getResLivetvList, getResLivetvLoading, getResVideosError, getResVideosList, getResVideosLoading, getResourceDetailsList, getResourceFavouritesList, getResourceFavouritesLoading, getTemplesList, getTemplesListError, getTemplesListLoading, getVideosError, getVideosLoading } from '../../../../redux/selectors/selectors';
import Error from '../error';
import Footer from '../footer/footer';
import YouTube from './youtube';
import showToast from '../../../../utils/showToast';
import TempleCard from '../../../common/card';
import ResourceCard from '../../../common/resourceCard';
import CustomToastContainer from '../../../common/CustomToastContainer';
import Hammer from 'react-hammerjs';

let last_rec = 0;

const ResourceFavourite = () => {
    const records_per_page = 12;
    const [filterId, setFilterId] = useState(null);
    const localEnv = false;
    const [isLoading, setIsLoading] = useState(false);
    const [did, setDid] = useState(0);
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('lng') || 5;
    const [totalRecords, setTotalRecords] = useState(0);
    const dispatch = useDispatch();

    const { favsList, favsLoading, favsError } = useSelector(state => ({
        favsList: getBooksFavouritesList(state),
        favsLoading: getBooksFavouritesLoading(state),
        favsError: getBooksFavouritesError(state),
    }));

    const { fList, fLoading, fError } = useSelector(state => ({
        fList: getResourceFavouritesList(state),
        fLoading: getResourceFavouritesLoading(state),
        fError: getFavouritesError(state),
    }));
    const { aList, aLoading, aError } = useSelector(state => ({
        aList: getResArticles(state),
        aLoading: getResArticlesLoading(state),
        aError: getResArticlesError(state),
    }));
    console.log(aList)
    const { mList, mLoading, mError } = useSelector(state => ({
        mList: getMantras(state),
        mLoading: getMantrasLoading(state),
        mError: getMantrasError(state),
    }));

    const { audioList, audioLoading, audioError } = useSelector(state => ({
        audioList: getResAudiosList(state),
        audioLoading: getResAudiosLoading(state),
        audioError: getResAudiosError(state),
    }));

    const { lList, lLoading, lError } = useSelector(state => ({
        lList: getResLivetvList(state),
        lLoading: getResLivetvLoading(state),
        lError: getResLivetvError(state),
    }));

    const { vList, vLoading, vError } = useSelector(state => ({
        vList: getResVideosList(state),
        vLoading: getResVideosLoading(state),
        vError: getResVideosError(state),
    }));

    useEffect(() => {
        dispatch(localEnv ? fetchGodNames(failure) : retrieveGodNames(storedLanguage))
    }, [storedLanguage]);

    const [filled, setFilled] = useState({}); // initialize as an empty object
    const [refresh, setRefresh] = useState(false);
    const [selectedContent, setSelectedContent] = useState('books');
    const getToken = Boolean(localStorage.getItem('token'));
    const [getFavouriteTemples, setGetFavoriteTemples] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [currentPage, setCurrentPage] = useState(0);
    const [lastRec, setLastRec] = useState(0);
    const [currentList, setCurrentList] = useState([]);
    const [filledTemples, setFilledTemples] = useState({});

    const success = (tname) => {
        showToast('success', `${t('label_added')} ${t(tname)} ${t('label_to_favourites')}`);
    };

    const warning = (tname) => {
        showToast('info', `${t('label_removed')} ${t(tname)} ${t('label_from_favourites')}`);
    };

    const failure = (msg) => {
        showToast('error', t('label_loginerror'))
    };


    function addFavourites(rid) {
        dispatch(postBooksFavourites('books', rid, failure));
        setFavList(prevFavList => {
            const updatedFavList = [...prevFavList, rid];
            localStorage.setItem('books', JSON.stringify(updatedFavList));
            return updatedFavList;
        });
    }

    function deleteFavourites(deleteId) {
        dispatch(removeBooksFavourites('books', deleteId, failure));
        setFavList(prevFavList => {
            const updatedFavList = prevFavList.filter(favId => favId !== deleteId);
            localStorage.setItem('books', JSON.stringify(updatedFavList));
            return updatedFavList;
        });
    }

    function addArticleFavourites(rid) {
        dispatch(postBooksFavourites('articles', rid, failure));
        setFavList(prevFavList => {
            const updatedFavList = [...prevFavList, rid];
            localStorage.setItem('articles', JSON.stringify(updatedFavList));
            return updatedFavList;
        });
    }

    function deleteArticleFavourites(deleteId) {
        dispatch(removeBooksFavourites('articles', deleteId, failure));
        setFavList(prevFavList => {
            const updatedFavList = prevFavList.filter(favId => favId !== deleteId);
            localStorage.setItem('articles', JSON.stringify(updatedFavList));
            return updatedFavList;
        });
    }

    function addMantrasFavourites(rid) {
        dispatch(postBooksFavourites('articles', rid, failure));
        setFavList(prevFavList => {
            const updatedFavList = [...prevFavList, rid];
            localStorage.setItem('articles', JSON.stringify(updatedFavList));
            return updatedFavList;
        });
    }

    function deleteMantrasFavourites(deleteId) {
        dispatch(removeBooksFavourites('articles', deleteId, failure));
        setFavList(prevFavList => {
            const updatedFavList = prevFavList.filter(favId => favId !== deleteId);
            localStorage.setItem('articles', JSON.stringify(updatedFavList));
            return updatedFavList;
        });
    }

    useEffect(() => {
        dispatch(fetchResourceFavourites('books', storedLanguage, last_rec));
    }, [storedLanguage, refresh]);


    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('i18nextLng'));
    }, []);


    // const getCurrentList = () => {
    //     let list;
    //     switch (activeKey) {
    //         case 'books':
    //             list = fList || [];
    //             break;
    //         case 'articles':
    //             list = aList || [];
    //             break;
    //         case '3': // Mantras
    //             list = mList || [];
    //             break;
    //         case '4': // Audios
    //             list = audioList || [];
    //             break;
    //         case '5': // Live TV
    //             list = lList || [];
    //             break;
    //         case '6': // Videos
    //             list = vList || [];
    //             break;
    //         default:
    //             list = [];
    //             break;
    //     }
    //     console.log(`List for activeKey ${activeKey}:`, list);
    //     return list;
    // };

    // // Fetch current list based on activeKey
    // const currentList = getCurrentList();

    // useEffect(() => {
    //     if (!isLoading && currentList.length > 0) {
    //         console.log("Data loaded successfully. Current list length:", currentList.length);
    //     }
    // }, [isLoading, activeKey, currentList]);

    // useEffect(() => {
    //     if (fList && fList.length > 0) {
    //         setTotalRecords(fList && fList[0] && fList[0].totalCount ? fList[0].totalCount : 0);
    //     } else if (aList && aList.length > 0) {
    //         setTotalRecords(aList && aList[0] && aList[0].totalCount ? aList[0].totalCount : 0);

    //     }
    // }, [fList, aList]);

    const fetchData = async (activeKey, offset) => {
        setIsLoading(true);
        try {
            let response;
            switch (activeKey) {
                case 'books':
                    response = await dispatch(fetchResourceFavourites('books', storedLanguage, offset));
                    break;
                case 'articles':
                    response = await dispatch(fetchResourceFavourites('articles', storedLanguage, offset));;
                    break;
                case '3': // Mantras
                    response = await dispatch(fetchResMantras(storedLanguage, offset));
                    break;
                case '4': // Audios
                    response = await dispatch(fetchResAudios(storedLanguage, offset));
                    break;
                case '5': // Live TV
                    response = await dispatch(fetchResLivetv(storedLanguage, offset));
                    break;
                case '6': // Videos
                    response = await dispatch(fetchResVideos(storedLanguage, offset));
                    break;
                default:
                    break;
            }

            // Assuming the response is an array of data, update the state accordingly
            setCurrentList(response || []);
        } finally {
            setIsLoading(false);
        }
    };

    const [offsets, setOffsets] = useState({
        books: 0,
        articles: 0,
        mantras: 0,
        audios: 0,
        livetv: 0,
        videos: 0,
    });

    const nextTemplesList = () => {
        const nextOffset = offsets[activeKey] + records_per_page;
        setOffsets(prev => ({ ...prev, [activeKey]: nextOffset }));
        fetchData(activeKey, nextOffset);
    };

    const prevTemplesList = () => {
        const prevOffset = Math.max(offsets[activeKey] - records_per_page, 0);
        setOffsets(prev => ({ ...prev, [activeKey]: prevOffset }));
        fetchData(activeKey, prevOffset);
    };

    const handleNextArticle = () => {
        const ridList = JSON.parse(localStorage.getItem('ridList')) || [];
        const currentIndex = parseInt(localStorage.getItem('currentRidIndex'), 10);
    
        if (currentIndex >= 0 && currentIndex < ridList.length - 1) {
          const nextIndex = currentIndex + 1;
          setSelectedArticleId(ridList[nextIndex]);
          localStorage.setItem('currentRidIndex', nextIndex);
    
        }
    
      };
    
      const handlePrevArticle = () => {
        const ridList = JSON.parse(localStorage.getItem('ridList')) || [];
        const currentIndex = parseInt(localStorage.getItem('currentRidIndex'), 10);
    
        if (currentIndex > 0) {
          const prevIndex = currentIndex - 1;
          setSelectedArticleId(ridList[prevIndex]);
          localStorage.setItem('currentRidIndex', prevIndex);
        }
      };

      const { resDetails } = useSelector(state => ({
        resDetails: getResourceDetailsList(state) || [], // Default to empty array if null/undefined
      }));

    useEffect(() => {
        switch (activeKey) {
            case 'books':
                setCurrentList(fList);
                break;
            case 'articles':
                setCurrentList(fList);
                break;
            case '3':
                setCurrentList(mList);
                break;
            case '4':
                setCurrentList(audioList);
                break;
            case '5':
                setCurrentList(lList);
                break;
            case '6':
                setCurrentList(vList);
                break;
            default:
                setCurrentList([]);
        }
    }, [activeKey, fList, aList, mList, audioList, lList, vList]);

    // let favList = (fLoading === true || !Array.isArray(fList)) ? [] : fList.map(id => id.tid);

    // function toggleFavorite(temple) {
    //     const index = favList.findIndex(t => t === temple.rid);
    //     if (index === -1) {
    //         addFavourites(temple.rid, temple.title);
    //         setGetFavoriteTemples(!getFavouriteTemples);
    //         if (getToken) {
    //             success(temple.title);
    //         }
    //     } else {
    //         deleteFavourites(temple.rid, temple.title);
    //         setGetFavoriteTemples(!getFavouriteTemples);
    //         setRefresh(!refresh);
    //     }

    // }
    const [favList, setFavList] = useState([]);

    useEffect(() => {
        if (getToken) {
            switch (selectedContent) {
                case 'books':
                    dispatch(fetchBooksFavourites('books'));
                    break;
                case 'articles':
                    dispatch(fetchBooksFavourites('articles'));
                    break;
                case 'mantras':
                    dispatch(fetchBooksFavourites('mantras'));
                    break;
                case 'audios':
                    dispatch(fetchBooksFavourites('audios'));
                    break;
                case 'livetv':
                    dispatch(fetchBooksFavourites('livetv'));
                    break;
                case 'videos':
                    dispatch(fetchBooksFavourites('videos'));
                    break;
                default:
                    break;
            }
        }
    }, [getToken, selectedContent, refresh]);



    // useEffect(() => {
    //     if (getToken) {
    //         const cachedFavourites = JSON.parse(localStorage.getItem('articles'));
    //         if (cachedFavourites) {
    //             setFavList(cachedFavourites);
    //         } else {
    //             dispatch(fetchResourceFavourites('books', storedLanguage, last_rec));
    //         }
    //     }
    // }, [getToken]);

    useEffect(() => {
        if (favsList && favsList.fav_list) {
            setFavList(favsList.fav_list);
            // localStorage.setItem('articles', JSON.stringify(fList.fav_list));
        }
    }, [fList]);

    function toggleFavorite1(temple) {
        if (getToken) {
            const templeId = temple.rid;

            if (favList.includes(templeId)) {
                // Temple is in favList, remove it
                deleteFavourites(templeId, temple.title);
                warning(temple.title);
                // Update favList by removing the temple ID
                setFavList(prevFavList => {
                    const updatedFavList = prevFavList.filter(id => id !== templeId);
                    localStorage.setItem('articles', JSON.stringify(updatedFavList));
                    return updatedFavList;
                });
            } else {
                // Temple is not in favList, add it
                addFavourites(templeId, temple.title);
                success(temple.title);
                // Update favList by adding the temple ID
                setFavList(prevFavList => {
                    const updatedFavList = [...prevFavList, templeId];
                    localStorage.setItem('articles', JSON.stringify(updatedFavList));
                    return updatedFavList;
                });
            }
        } else {
            // Handle the case when there's no token
            failure("Please log in to favorite temples");
        }
    }

    function toggleFavorite(temple) {
        if (getToken) {
            const templeId = temple.rid;
            if (favList.includes(templeId)) {
                // Temple is in favList, remove it
                deleteFavourites(templeId, temple.title);
                // warning(temple.title);
                // Update favList by removing the temple ID
                // setFavList(prevFavList => prevFavList.filter(id => id !== templeId));
                dispatch(removeBooksFavourites('articles', templeId, failure));
                setFavList(prevFavList => {
                    const updatedFavList = prevFavList.filter(favId => favId !== templeId);
                    localStorage.setItem('articles', JSON.stringify(updatedFavList));
                    return updatedFavList;
                });
                warning(`${temple.title} removed from favorites`);
            } else {
                // Temple is not in favList, add it
                addFavourites(templeId, temple.title);
                // success(temple.title);
                // Update favList by adding the temple ID
                // setFavList(prevFavList => [...prevFavList, templeId]);
                dispatch(postBooksFavourites('articles', templeId, failure));
                setFavList(prevFavList => {
                    const updatedFavList = [...prevFavList, templeId];
                    localStorage.setItem('articles', JSON.stringify(updatedFavList));
                    return updatedFavList;
                });
                success(`${temple.title} added to favorites`);
            }
        } else {
            // Handle the case when there's no token (e.g., show a message or redirect to login)
            failure("Please log in to favorite temples");
        }
    }


    function toggleFilled(id) {
        setFilledTemples((prevFilled) => ({
            ...prevFilled,
            [id]: !prevFilled[id],
        }));
    }


    const temple = fList;

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    const handleNav = () => {
        // navigate(`/templeDetails/${temple.tid}?prevPage=${window.location.pathname}`)
    };

    const [activeKey, setActiveKey] = useState('1');

    const onChange = (key) => {
        setActiveKey(key);
    };

    function getYouTubeVideoId(url) {
        // This function extracts the YouTube video ID from a YouTube URL
        const regex = /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)/;
        const match = url.match(regex);
        if (match && match[1]) {
            return match[1];
        } else {
            return null;
        }
    }

    const handleButtonClick = (contentType) => {
        setSelectedContent(contentType);
        setActiveKey(contentType);
        // Fetch the respective content based on the button clicked
        switch (contentType) {
            case 'books':
                dispatch(fetchResourceFavourites('books', storedLanguage, last_rec));
                break;
            case 'articles':
                dispatch(fetchResourceFavourites('articles', storedLanguage, last_rec));
                break;
            case 'mantras':
                dispatch(fetchResMantras(storedLanguage, last_rec));
                break;
            case 'audios':
                dispatch(fetchResAudios(storedLanguage, last_rec));
                break;
            case 'livetv':
                dispatch(fetchResLivetv(storedLanguage, last_rec));
                break;
            case 'videos':
                dispatch(fetchResVideos(storedLanguage, last_rec));
                break;
            default:
                break;
        }
    };

    const renderContent = () => {
        switch (selectedContent) {
            case 'books':
                return renderTiles(fLoading, 'books', fList);
            case 'articles':
                return renderTiles(fLoading, 'articles', fList);
            case 'mantras':
                return renderTiles(mLoading, 'mantras', mList);
            case 'audios':
                return renderTiles(audioLoading, 'audios', audioList);
            case 'livetv':
                return renderTiles(lLoading, 'livetv', lList);
            case 'videos':
                return renderTiles(vLoading, 'videos', vList);
            default:
                return null;
        }
    };

    const [isArticleModalVisible, setIsArticleModalVisible] = useState(false);
    const [selectedArticleId, setSelectedArticleId] = useState(null);

    const handleArticleCancel = () => {
        setIsArticleModalVisible(false);
      };

      const capitalizeFirstLetter = (string) => {
        if (!string) return ''; // Return an empty string if string is null or undefined
        return string.charAt(0).toUpperCase() + string.slice(1);
      };

    const closeModal = () => {
        setIsArticleModalVisible(false);
    };

    const handleArticleClick = (temple) => {
        // Merge all available `rid` lists
        const allDetails = [...(fList || []), ...(aList || []), ...(mList  || [])];

        if (allDetails.length > 0) {
            const ridList = allDetails.map((article) => article.rid); // Extract all `rid`s
            localStorage.setItem('ridList', JSON.stringify(ridList));
            localStorage.setItem('currentRidIndex', ridList.indexOf(temple.rid)); // Store current index
        }

        // Check if the src_url is "www.templeswiki.com"
        if (temple.src_url === 'www.templeswiki.com') {
            localStorage.setItem('src_url', temple.src_url); // Store src_url in localStorage
            setSelectedArticleId(temple.rid); // Set selected article
            setIsArticleModalVisible(true); // Show popup
        } else {
            // Make sure the URL starts with http:// or https://
            let templeUrl = temple.src_url.trim();

            // If no protocol is present, prepend http://
            if (!templeUrl.startsWith('http://') && !templeUrl.startsWith('https://')) {
                templeUrl = `http://${templeUrl}`;
            }

            // Detect if the device is mobile
            const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

            if (isMobile) {
                // For mobile devices, navigate to the absolute URL
                window.location.href = templeUrl;
            } else {
                // For web, try opening the URL in a new tab
                const newWindow = window.open(templeUrl, '_blank', 'noopener,noreferrer');

                if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
                    // Fallback to opening in the same tab if popups are blocked
                    window.location.href = templeUrl;
                }
            }
        }
    };

    const renderTiles = (loading, resourceType, list) => {
        list = list || [];
        const isVideoOrLiveTV = resourceType === 'livetv' || resourceType === 'videos';
        const isAudio = resourceType === 'audios';
        const target = (resourceType === 'mantras' ? "_self" : "_blank");
        return (
            <div className="container search">
                {loading ? (
                    <Error />
                ) : (
                    <div className="tiles-main">
                        {list.length === 0 ? (
                            <Result
                                status="404"
                                title={t("label_nodata_found")}
                                subTitle={`Sorry, no favourite ${resourceType} found.`}
                            />
                        ) : (
                            isVideoOrLiveTV ? (
                                list.map((resource) => (
                                    <YouTube
                                        key={resource.rid}
                                        temple={resource}
                                        toggleFavorite={toggleFavorite1}
                                        toggleFilled={toggleFilled}
                                        favList={favList}
                                        getYouTubeVideoId={getYouTubeVideoId}
                                    />
                                ))
                            ) : (
                                isAudio ? (
                                    list.map((resource, index) => (
                                        <ResourceCard
                                            key={resource.rid}
                                            id={resource.rid}
                                            imgpath={resource.imgpath}
                                            title={resource.title}
                                            city={resource.info ? resource.info.split("<BR>").join("\n") : ''}
                                            temple={resource}
                                            favList={favList}
                                            toggleFavorite={toggleFavorite1}
                                            toggleFilled={toggleFilled}
                                            link={resource.src_url}
                                            text={resourceType}
                                            publish_date={resource.publish_date}
                                            author={resource.author}
                                            target={target}
                                            handleClick={() => handleArticleClick(temple)}
                                        />

                                    ))
                                ) : (
                                    list.map((resource) => (
                                        <ResourceCard
                                            key={resource.rid}
                                            id={resource.rid}
                                            imgpath={resource.imgpath}
                                            title={resource.title}
                                            city={resource.info ? resource.info.split("<BR>").join("\n") : ''}
                                            temple={resource}
                                            favList={favList}
                                            toggleFavorite={toggleFavorite1}
                                            toggleFilled={toggleFilled}
                                            link={resource.src_url}
                                            text={resourceType}
                                            publish_date={resource.publish_date}
                                            author={resource.author}
                                            target={target}
                                            handleClick={() => handleArticleClick(resource)}
                                        // text={text}
                                        />
                                    ))
                                )
                            )
                        )}
                    </div>
                )}
            </div>
        );
    };


    useEffect(() => {
        if (activeKey === '1') {
            dispatch(fetchResourceFavourites('books', storedLanguage, last_rec));
        } else if (activeKey === '2') {
            dispatch(fetchResArticles(storedLanguage, last_rec));
        } else if (activeKey === '3') {
            dispatch(fetchResMantras(storedLanguage, last_rec));
        }
        else if (activeKey === '4') {
            dispatch(fetchResAudios(storedLanguage, last_rec));
        }
        else if (activeKey === '5') {
            dispatch(fetchResLivetv(storedLanguage, last_rec));
        }
        else if (activeKey === '6') {
            dispatch(fetchResVideos(storedLanguage, last_rec));
        }
    }, [activeKey, storedLanguage, last_rec, dispatch]);




    return (
        <div className='mainsec'>

            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span className="bc-link">{t("label_library_fav")}</span>
                </div>
            </div>
            <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link'>

                <Link style={{ color: 'black' }} to='/'><span class="bc-active-link">{t('Home')}</span></Link> -
                &nbsp;<Link style={{ color: 'black' }} to='/library'><span class="bc-active-link">{t('label_library')}</span></Link> - <span class="bc-link">{t('label_library_fav')}</span>
            </div>
            <div class="mainsec-block">
                {/* <div class="container cat-txt ">
                    <div class="cat-detail">
                        <span class="f3 d-flex justify-content-between">
                            <div><b>{t("label_library_fav")}</b> &nbsp; &nbsp;<i class="fa-solid fa-volume-high"></i></div>
                        </span>
                        <p class="f1">{t('label_fav_page_msg')}</p>
                    </div>
                </div> */}
                <div class="details-icons-main mb-3"><div class="container details-icons">
                    <button
                        className={`details-icons-list  ${selectedContent === 'books' ? 'selected' : ''}`}
                        onClick={() => handleButtonClick('books')}
                    >
                        {t('label_books')}
                    </button>
                    <button
                        className={`details-icons-list  ${selectedContent === 'articles' ? 'selected' : ''}`}
                        onClick={() => handleButtonClick('articles')}
                    >
                        {t('label_articles')}
                    </button>
                    <button
                        className={`details-icons-list  ${selectedContent === 'mantras' ? 'selected' : ''}`}
                        onClick={() => handleButtonClick('mantras')}
                    >
                        {t('label_mantras')}
                    </button>
                    <button
                        className={`details-icons-list  ${selectedContent === 'audios' ? 'selected' : ''}`}
                        onClick={() => handleButtonClick('audios')}
                    >
                        {t('label_audios')}
                    </button>
                    <button
                        className={`details-icons-list  ${selectedContent === 'livetv' ? 'selected' : ''}`}
                        onClick={() => handleButtonClick('livetv')}
                    >
                        {t('label_livetv')}
                    </button>
                    <button
                        className={`details-icons-list  ${selectedContent === 'videos' ? 'selected' : ''}`}
                        onClick={() => handleButtonClick('videos')}
                    >
                        {t('label_videos')}
                    </button>
                </div></div>
                {renderContent()}


                <div className="next-row">
                    <a
                        className="button_move"
                        onClick={prevTemplesList}
                        disabled={offsets[activeKey] === 0 || isLoading} // Check the offset for the activeKey
                    >
                        {t("label_previous")}
                    </a>

                    <a
                        className="button_move"
                        onClick={nextTemplesList}
                        disabled={currentList.length < records_per_page || isLoading} // Disable if less than a full page or loading
                    >
                        {t("label_next")}
                    </a>
                    {(selectedContent === 'articles' || selectedContent === 'mantras') && (
                        <div class="popup-bg" style={{ display: isArticleModalVisible ? 'flex' : 'none' }}>
                            <CustomToastContainer />
                            <div class="popup-main-artical animate__animated animate__fadeInUp "

                            >

                                <div class="popup-main-header-artical">
                                    <div class="flex-grow-1 overflow-hidden">
                                        <span class="popup-main-header-text-artical">{capitalizeFirstLetter(resDetails && resDetails.title) || 'N/A'}</span>
                                        <div class="popup-close" onClick={handleArticleCancel}><i class="fa-solid fa-xmark"></i></div>
                                        <div class="popup-back" onClick={handleArticleCancel}><i class="fa-solid fa-chevron-left"></i></div>
                                        <p><span class="article-titletxt">{t('label_author')} :</span> {capitalizeFirstLetter(resDetails && resDetails.author) || 'N/A'}</p>
                                        <p><span class="article-titletxt">{t('label_pub_date')} :</span> {capitalizeFirstLetter(resDetails && resDetails.publish_date) || 'N/A'}</p>
                                    </div>
                                    <div class="article-image"></div>
                                    <div>
                                    </div>

                                </div>
                                <Hammer
                                    onSwipe={(event) => {
                                        if (event.direction === 2) { // Left swipe for next
                                            handleNextArticle();
                                        } else if (event.direction === 4) { // Right swipe for previous
                                            handlePrevArticle();
                                        }
                                    }}
                                >
                                    <div class="popup-main-container-artical">
                                        <div class="htinner-popup-main-container-artical ">
                                            {/* <Link className='ignore-styles' to={`/editResource/${selectedArticle && selectedArticle.rid}?prevPage=${window.location.pathname}`}>
                      <img
                        className="grp-descr-img"
                        alt={selectedArticle ? selectedArticle.tname : 'Default Temple'}
                        src={selectedArticle && selectedArticle.imgpath ? getImageAPI() + selectedArticle.imgpath : deftemples}
                      />
                    </Link> */}


                                            <div className='dialog-content-text-artical ' style={{ whiteSpace: "pre-wrap" }}>
                                                <Hammer
                                                    onSwipe={(event) => {
                                                        if (event.direction === 2) { // Left swipe for next
                                                            handleNextArticle();
                                                        } else if (event.direction === 4) { // Right swipe for previous
                                                            handlePrevArticle();
                                                        }
                                                    }}
                                                >
                                                    <div class={`dialog-content-text-articaltxt `}
                                                    >

                                                        {/* <p class="detailstxt-artical-title"> {capitalizeFirstLetter(selectedArticle && selectedArticle.title) || 'N/A'}</p> */}
                                                        <Hammer
                                                            onSwipe={(event) => {
                                                                if (event.direction === 2) { // Left swipe for next
                                                                    handleNextArticle();
                                                                } else if (event.direction === 4) { // Right swipe for previous
                                                                    handlePrevArticle();
                                                                }
                                                            }}
                                                        >

                                                            <p class={`dialog-content-text-articaltxt-articalmain-data ${selectedContent === 'mantras' ? 'scrollable' : ''}`}>
                                                                {resDetails && resDetails.txt
                                                                    ? capitalizeFirstLetter(
                                                                        resDetails.txt
                                                                            .split("<BR>")
                                                                            .map(line => {
                                                                                if (/<B>.*<\/B>/i.test(line)) {
                                                                                    return `**${line.replace(/<B>|<\/B>/gi, "")}**`;
                                                                                }
                                                                                return line;
                                                                            })
                                                                            .join("\n")
                                                                    )
                                                                    : ''
                                                                }
                                                            </p>


                                                        </Hammer>

                                                    </div>
                                                </Hammer>
                                                <div class="like-share-section " >
                                                    <button class="detailstxt-artical-icon ml-3" onClick={handlePrevArticle} style={{ float: 'left', marginRight: "2px" }}>
                                                        <i class="fa-solid fa-chevron-left"></i></button>

                                                    <div class="article_btm-btns">
                                                        <Link className='detailstxt-artical-icon' to={`/editResource/${selectedArticleId}`}>
                                                            <i class=" fa-solid fa-user-pen"></i>
                                                        </Link>
                                                        <button class="detailstxt-artical-icon">
                                                            {/* <i className="fa-solid fa-heart" style={{ fontSize: '24px' }}></i>
                            </button>
                            <button class="detailstxt-artical-icon">
                              <i className="fa-solid fa-share" style={{ fontSize: '24px' }}></i> */}
                                                            <a
                                                                className="share-button"
                                                                onClick={() => {
                                                                    if (navigator.share) {
                                                                        navigator.share({
                                                                            title: "Know " + resDetails.title,
                                                                            text: "Know " + resDetails.txt,
                                                                            url: `https://www.templeswiki.com/templeDetails/${temple.rid}?prevPage=${window.location.pathname}`
                                                                        })
                                                                            .then(() => console.warn("Share successful."))
                                                                            .catch((error) => console.error("Error sharing:", error));
                                                                    } else {
                                                                        // Fallback for browsers that do not support the Web Share API
                                                                        console.error("Web Share API not supported.");
                                                                    }
                                                                }}
                                                            >
                                                                <i title="Share" className="fa-solid fa-share-nodes" style={{ fontSize: '24px', marginRight: '112px' }}></i>
                                                            </a>

                                                            <a
                                                                className="share-button"
                                                                onClick={() => toggleFavorite(selectedArticleId, temple.title)}
                                                            >
                                                                {favList.some(t => t === temple.rid) ? (
                                                                    <i className="fa-solid fa-heart"></i>
                                                                ) : (
                                                                    <i className="fa-regular fa-heart"></i>
                                                                )}
                                                            </a>

                                                            {/* <a
                                className="share-button"
                                onClick={() => toggleFavorite(temple, title)}
                            >
                                {favList.some(t => t === temple.rid) ? (
                                    <i className="fa-solid fa-heart" style={{fontSize: '24px'}}></i>
                                ) : (
                                    <i className="fa-regular fa-heart" style={{fontSize: '24px'}}></i>
                                )}
                            </a> */}
                                                            {/* <i className="fa-solid fa-heart" style={{ fontSize: '24px' }}></i> */}

                                                        </button>
                                                    </div>
                                                    {isLoading && (<Error />)}
                                                    <button class="detailstxt-artical-icon mr-3" onClick={handleNextArticle} style={{ float: 'right' }}>
                                                        <i class="fa-solid fa-chevron-right"></i></button>
                                                </div>

                                            </div>


                                        </div>
                                        <div class="popup-main-footer d-none">
                                            <button className="button_move" onClick={handlePrevArticle} style={{ float: 'left', marginRight: "2px" }}>{t('label_previous')}</button>
                                            <button className="button_move" onClick={handleNextArticle} style={{ float: 'right' }}>{t('label_next')}</button>
                                        </div>

                                    </div>
                                </Hammer>
                            </div>
                        </div>
                    )}
                </div>




                <Footer />
            </div>
        </div>
    );
};

export default ResourceFavourite;
